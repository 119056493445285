import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaPinterestP, FaLinkedin, FaInstagram, FaVimeoV, FaDribbble, FaBehance, FaEnvelopeOpen, FaTiktok } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import ServiceData from "../../data/service/ServiceMain.json";
import { slugify } from '../../utils';

const getServiceData = ServiceData;

const FooterOne = ({parentClass}) => {
    
    return (
        <footer className={`footer-area ${parentClass}`}>
            <div className="container">
                <div className="footer-main">
                    <div className="row">
                        <div className="col-xl-6 col-lg-5">
                            <div className="footer-widget border-end">
                                <div className="footer-newsletter">
                                    <h1></h1>
                                    <h2 className="title">카카오톡 실시간 상담</h2>
                                    <p>아래 카카오톡 아이디로 연락주시면 친절하게 안내해드리겠습니다.</p>
                                    <form>
                                        <div className="input-group">
                                            <button className="subscribe-btn" style={{ backgroundColor: 'yellow', color: 'black', fontWeight: 'bold'}} type="submit">카카오톡: vip966</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </footer>
    )
}

export default FooterOne;