import React, {useEffect, useMemo, useState, useRef} from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import BannerOne from '../component/banner/BannerOne';
import SectionTitle from '../elements/section-title/SectionTitle';

import {Element, animatedScroll as scroll, Link} from 'react-scroll';
import Select from 'react-select';

import { useTable } from 'react-table';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import "./RealTimeTable.css";


const styles = {
    container: {
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
    },
    banner: {
      height: '50vh',
      backgroundImage: 'url(https://example.com/your-image.jpg)',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    content: {
      height: '50vh',
      padding: '20px',
    },
    inputContainer: {
      position: 'relative',
      width: '100%',
      margin: '5px 0',
    },
    inputLabel: {
      position: 'absolute',
      top: '50%',
      left: '10px',
      transform: 'translateY(-50%)',
      pointerEvents: 'none',
      backgroundColor: '#333',
      color: '#fff',
      padding: '0 5px',
      borderRadius: '3px',
      transition: '0.2s ease all',
    },
    mergedInput: {
      width: '100%',
      padding: '10px 10px 10px 70px', // Add padding to the left to avoid label
      boxSizing: 'border-box',
    },
    inputLabelFocus: {
      top: '0',
      transform: 'translateY(-100%)',
      fontSize: '0.75em',
      color: '#fff',
      backgroundColor: '#333',
    },
    formBox: {
        backgroundColor:  'white',
    padding: 50,
    borderRadius: 30,
    }
  };


const DataTable = () => {
    const tableStyles= {
        table: {
            width: '100%',
        }
    }
  const [data, setData] = useState([]);
  const [lastTimestamp, setLastTimestamp] = useState(Date.now());

  const fetchData = async () => {
    try {
      // const response = await fetch('YOUR_API_ENDPOINT'); // Replace with your API endpoint
      // const result = await response.json();

      // Assuming the new rows are always at the end and your data has a 'timestamp' field
      // const newRows = result.filter(row => new Date(row.timestamp) > new Date(lastTimestamp));

        const newRows = [{
            id: 234,
            name: '43',
            timestamp: Date.now()
        }]

      if (newRows.length > 0) {
        setLastTimestamp(newRows[newRows.length - 1].timestamp);

        setData(prevData => {
            const updatedData = [...prevData, ...newRows]
            const sortedData = updatedData.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

            return sortedData.slice(0, 20);
        });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    // Initial fetch
    fetchData();

    // Set up interval to fetch data every 5 seconds
    const interval = setInterval(fetchData, 5000);

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, [lastTimestamp]);

  return (
    <table >
      <thead>
        <tr>
          <th>교환정보</th>
          <th></th>
          <th>상태</th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr key={index}>
            <td>{row.name}</td>
            <td>{row.name}</td>
            <td>{row.timestamp}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const bankOptions = [
    { value: '한국은행', label: '한국은행'},
    { value: '산업은행', label: '산업은행'},
    { value: '기업은행', label: '기업은행'},
    { value: '국민은행', label: '국민은행'},
    { value: 'KEB하나은행', label: 'KEB하나은행'},
    { value: '수협중앙회', label: '수협중앙회'},
    { value: '수출입은행', label: '수출입은행'},
    { value: '농협은행', label: '농협은행'},
    { value: '지역농축협', label: '지역농축협'},
    { value: '우리은행', label: '우리은행'},
    { value: 'SC은행', label: 'SC은행'},
    { value: '한국씨티은행', label: '한국씨티은행'},
    { value: '대구은행', label: '대구은행'},
    { value: '부산은행', label: '부산은행'},
    { value: '광주은행', label: '광주은행'},
    { value: '제주은행', label: '제주은행'},
    { value: '전북은행', label: '전북은행'},
    { value: '경남은행', label: '경남은행'},
    { value: '새마을금고중앙회', label: '새마을금고중앙회'},
    { value: '신협중앙회', label: '신협중앙회'},
    { value: '상호저축은행', label: '상호저축은행'},
    { value: '산림조합중앙회', label: '산림조합중앙회'},
    { value: '대화은행', label: '대화은행'},
    { value: '우체국', label: '우체국'},
    { value: 'KEB하나은행', label: 'KEB하나은행'},
    { value: '신한은행', label: '신한은행'},
    { value: '케이뱅크', label: '케이뱅크'},
    { value: '카카오뱅크', label: '카카오뱅크'},
    { value: '유안타증권', label: '유안타증권'},
    { value: '현대증권', label: '현대증권'},
    { value: '미래에셋대우', label: '미래에셋대우'},
    { value: '삼성증권', label: '삼성증권'},
    { value: '한국투자증권', label: '한국투자증권'},
    { value: 'NH투자증권', label: 'NH투자증권'},
    { value: '교보증권', label: '교보증권'},
    { value: '하이투자증권', label: '하이투자증권'},
    { value: 'HMC투자증권', label: 'HMC투자증권'},
    { value: '키움증권', label: '키움증권'},
    { value: '이베스트투자증권', label: '이베스트투자증권'},
    { value: 'SK증권', label: 'SK증권'},
    { value: '대신증권', label: '대신증권'},
    { value: '아이엠투자증권', label: '아이엠투자증권'},
    { value: '한화투자증권', label: '한화투자증권'},
    { value: '하나대투증권', label: '하나대투증권'},
    { value: '신한금융투자', label: '신한금융투자'},
    { value: '동부증권', label: '동부증권'},
    { value: '유진투자증권', label: '유진투자증권'},
    { value: '메리츠종합금융증권', label: '메리츠종합금융증권'},
    { value: '부국증권', label: '부국증권'},
    { value: '신영증권', label: '신영증권'},
    { value: 'LIG투자증권', label: 'LIG투자증권'},
    { value: '토스뱅크', label: '토스뱅크'},
    { value: '미래에셋증권', label: '미래에셋증권'},
]

const DigitalAgency = () => {
    const [data, setData] = useState([
        {
          id: 1,
          name: 'Date Entry',
          date: new Date().toLocaleString(),
            price: 0,
            status: '대기중',
        },
        {
          id: 2,
          name: 'Date Entry',
          date: new Date().toLocaleString(),
            price: 0,
            status: '대기중',
        }
    ]);


    const [inputs, setInputs] = useState([{id: 1, value: ''}, {id: 2, value: ''}, {id: 3, value: ''}, {
        id: 4,
        value: ''
    }, {id: 5, value: ''}]);
    const [bankInputs, setBankinputs] = useState([{id: '은행', value: ''}, {id: '계좌번호', value: ''}, {
        id: '입금자명',
        value: ''
    }, {id: '연락처', value: ''}]);

    const [bank, setBank] = useState(null);
    const [accountNumber, setAccountNumber] = useState(null);
    const [accountHolder, setAccountHolder] = useState(null);
    const [contactNumber, setContactNumber] = useState(null);

    const handleInputChange = (id, event) => {
        const formattedValue = formatInput(event.target.value);
        const newInputs = inputs.map(input => {
            if (input.id === id) {
                return {...input, value: formattedValue};
            }
            return input;
        });
        setInputs(newInputs);
    };

    const formatInput = (value) => {
        const digits = value.replace(/\D/g, '').substring(0, 16);
        const groups = digits.match(/.{1,4}/g);
        return groups ? groups.join('-') : '';
    };

    const addInput = () => {
        const newInput = {id: inputs.length + 1, value: ''};
        setInputs([...inputs, newInput]);
  };
    return (
        <>
        <main className="main-wrapper">
            {/*<RTTable />*/}
            <HeaderOne />
            <BannerOne DataTable={DataTable}/>
            <div className="section section-padding-2 bg-color-dark">
                <div className="container">
                    <SectionTitle
                        subtitle="메트로핀"
                        title="빠른 서비스"
                        description=""
                        textAlignment="heading-light-left"
                        textColor=""
                    />

                </div>
                <ul className="list-unstyled shape-group-10">
                    <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/line-9.png"} alt="Circle" /></li>
                    <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-42.png"} alt="Circle" /></li>
                    <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-43.png"} alt="Circle" /></li>
                </ul>
            </div>

            <Element name="pin">
                <section className="section section-padding-equal bg-color-light">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-5 col-lg-5 offset-xl-1 m-1">
                                <div className="contact-form-box">
                                    <h5>핀 수동 입력</h5>
                                    {inputs.map(input => (
                                        <div key={input.id}
                                             style={{backgroundColor: input.color, padding: '10px', margin: '5px'}}>
                                            <div style={styles.inputContainer}>
                                                <label
                                                    htmlFor={`input-${input.id}`}
                                                    style={
                                                        input.value ? {...styles.inputLabel, ...styles.inputLabelFocus} : styles.inputLabel
                                                    }
                                                >
                                                    PIN {input.id}:
                                                </label>
                                                <input
                                                    id={`input-${input.id}`}
                                                    type="text"
                                                    value={input.value}
                                                    onChange={(event) => handleInputChange(input.id, event)}
                                                    style={styles.mergedInput}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                    <button onClick={addInput} className="axil-btn btn-fill-primary btn-sm">
                                        + 추가하기
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="contact-form-box">
                                    <h5>입금정보 확인</h5>
                                    <div key="bank-input"
                                         style={{backgroundcolor: '#fff', padding: '10px', margin: '5px'}}>
                                        <div style={styles.inputContainer}>
                                            <label htmlFor="input-bank"
                                                   style={bank ? {...styles.inputLabel, ...styles.inputLabelFocus} : styles.inputLabel}>
                                                은행
                                            </label>
                                            <Select
                                                options={bankOptions}
                                                placeholder={"입금 은행"}
                                                value={bank}
                                                onChange={(value) => setBank(value ? value : '')}
                                                style={styles.mergedInput}
                                            />
                                        </div>
                                    </div>
                                    <div key="account-number-input"
                                         style={{backgroundcolor: '#fff', padding: '10px', margin: '5px'}}>
                                        <div style={styles.inputContainer}>
                                            <label htmlFor="input-account-number"
                                                   style={accountNumber ? {...styles.inputLabel, ...styles.inputLabelFocus} : styles.inputLabel}>
                                                계좌번호
                                            </label>
                                            <input
                                                id="input-account-number"
                                                type="text"
                                                value={accountNumber}
                                                onChange={(event) => setAccountNumber(event.target.value)}
                                                style={styles.mergedInput}
                                            />
                                        </div>
                                    </div>
                                    <div key="account-holder-input"
                                         style={{backgroundcolor: '#fff', padding: '10px', margin: '5px'}}>
                                        <div style={styles.inputContainer}>
                                            <label htmlFor="input-account-holder"
                                                   style={accountHolder ? {...styles.inputLabel, ...styles.inputLabelFocus} : styles.inputLabel}>
                                                입금자명
                                            </label>
                                            <input
                                                id="input-account-holder"
                                                type="text"
                                                value={accountHolder}
                                                onChange={(event) => setAccountHolder(event.target.value)}
                                                style={styles.mergedInput}
                                            />
                                        </div>
                                    </div>
                                    <div key="contact-number-input"
                                         style={{backgroundcolor: '#fff', padding: '10px', margin: '5px'}}>
                                        <div style={styles.inputContainer}>
                                            <label htmlFor="input-contact-number"
                                                   style={contactNumber ? {...styles.inputLabel, ...styles.inputLabelFocus} : styles.inputLabel}>
                                                연락처
                                            </label>
                                            <input
                                                id="input-contact-number"
                                                type="text"
                                                value={contactNumber}
                                                onChange={(event) => setContactNumber(event.target.value)}
                                                style={styles.mergedInput}
                                            />
                                        </div>
                                    </div>
                                    <button onClick={addInput}
                                            className="axil-btn btn-fill-primary btn-sm float-end"
                                    >
                                        교환신청
                                    </button>
                                    <br/><br/>

                                </div>
                            </div>


                        </div>
                    </div>
                    {/*<ul className="shape-group-6 list-unstyled">*/}
                    {/*    <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-7.png"}*/}
                    {/*                                       alt="Bubble"/></li>*/}
                    {/*    <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-4.png"}*/}
                    {/*                                       alt="line"/></li>*/}
                    {/*    <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/line-5.png"}*/}
                    {/*                                       alt="line"/></li>*/}
                    {/*</ul>*/}
                </section>
            </Element>

            <FooterOne parentClass=""/>
        </main>
        </>
    )
}

export default DigitalAgency;

